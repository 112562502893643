import React, { useState } from 'react'
import DynamicCheckPreview from '../../../../assets/Dynamism_check.png'

export default function SDKConfigPreviewComp({ tab ,themeColor="#017da3",selectedEndpoints=[],selectedCountry,description='Verification Made simple',widgetName='Sample Test'}: any) {
    // console.log({selectedCountry,selectedEndpoints})
    const [currentCode,setCurrentCode] = useState('')
    // 
    return (
        <div>
            <div className="border rounded-3 p-5">
                <div className="card p-4">
                    <div className="card-body">
                        <h5>{widgetName}</h5>
                        <p>
                            {description}
                        </p>
{/* {JSON.stringify(selectedCountry?.country)} */}
                        {
                        tab === 'dynamism_checks' ? 
                        (
                            <img src={DynamicCheckPreview} alt="" />
                        ) : (
                            <>
                                <div>
                                    <label htmlFor="country">Select Country </label>
                                    <select className="form-select"
                                    onChange={e=>{
                                        setCurrentCode(e.target.value)
                                    }}
                                    >
                                                <option value='#'>
                                                    Select Country
                                                </option>

                                        {
                                            selectedCountry?.map((d:any)=>(
                                                <option value={d?.code}>{d?.country}</option>

                                            ))
                                        }
                                    </select>
                                </div>
                                {
                                     selectedEndpoints?.filter((d:any)=>d.country_code===currentCode).length!==0?
                                     <div>
                                     <label htmlFor="country">Select Verification Channel </label>
                                     <select className="form-select">
                                         {
                                              selectedEndpoints?.filter((d:any)=>d.country_code===currentCode)?.map((d:any,index:number)=>(
                                                 <option value="">{d?.endpoint_name}</option>
 
                                              ))
                                         }
                                     </select>
                                 </div>:''
                                }

                            </>
                        )}
{/* 
                        <button
                        disabled
                        className="btn  w-100 py-3 mt-4"
                        style={{backgroundColor:themeColor,'color':'white'}}
                        > Proceed</button> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
